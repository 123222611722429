body {
  overflow: hidden;
  margin: 0;
  cursor: pointer;
  width: 100vw;
  height: 100vh;
}
canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.penrose .dg.ac {
  z-index: 100;
  right: 75px;
}

.links {
  z-index: 100;
  position: absolute;
  bottom: 20%;
  text-align: center;
  width: 100%;
  font-family: monospace;
}
